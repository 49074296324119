import { Marker } from "@react-google-maps/api";
import React from "react";
import useMediaQuery from "../Common/Hooks/useMediaQuery.jsx";
import Map from "../Common/Map/index.jsx";

const ContactMapApp = (props) => {
  const zoom = 15;
  const customClass = "contactmap--map";
  const icon = "https://www.cresa.com/images/icons/map-pin-blue.png";
  const pin = { lat: props.latitude, lng: props.longitude };
  const isMobile = useMediaQuery(980);

  console.log(props);

  return (
    <section>
      <div className="contactmap">
        <Map
          zoom={zoom}
          draggable={false}
          center={{
            lat: props.latitude,
            lng: props.longitude + (isMobile ? 0 : 0.01),
          }}
          customClass={customClass}
        >
          <Marker key={props.name} position={pin} icon={icon} />
        </Map>
        <aside className="contactmap--box">
          <h3 className="h3-alt-lg">{props.name}</h3>
          <div className="contactmap--box-data">
            <span>
              <img src="/images/icons/map-pin-blue.png" alt="Map pin" />
            </span>
            <div>
              <p>{props.address1}</p>
              <p>{props.address2}</p>
              <p>{props.address3}</p>
              {props.address4 && 
                <p>{props.address4}</p>
              }
              <a href={`tel:${props.phone}`}>
                <p>{`Phone: ${props.phone}`}</p>
              </a>
              {props.fax && 
                <p>{`Fax: ${props.fax}`}</p>
              }


              {props.socials.rss && props.socials.rss.Url && 
                <a className="rss-link" target={props.socials.rss.Target} href={props.socials.rss.Url}>
                  <p>{props.socials.rssCaption}</p>
                </a>
              }

              {props.socials &&             
                <div className="social-links">
                  <ul>
                    {props.socials.facebook &&
                      <li>
                          <a href={props.socials.facebook.Url} target="_blank"><img src="/images/icons/fb.svg" alt="facebook icon" /></a>
                      </li>
                    }
                    {props.socials.instagram &&
                      <li>
                          <a href={props.socials.instagram.Url} target="_blank"><img src="/images/icons/ig.svg" alt="instagram icon" /></a>
                      </li>
                    }
                    {props.socials.linkedin &&
                      <li>
                          <a href={props.socials.linkedin.Url} target="_blank"><img src="/images/icons/ln.svg" alt="linked-in icon" /></a>
                      </li>
                    }
                    {props.socials.twitter &&
                      <li>
                          <a href={props.socials.twitter.Url} target="_blank"><img src="/images/icons/tw.svg" alt="twitter icon" /></a>
                      </li>
                    }
                    {props.socials.youtube &&
                      <li>
                          <a href={props.socials.youtube.Url} target="_blank"><img src="/images/icons/yt.svg" alt="youtube icon" /></a>
                      </li>
                    }
                  </ul>
                </div>
              }
            </div>
          </div>
        </aside>
      </div>
    </section>
  );
};

export default ContactMapApp;
