import React, { useState, useMemo } from "react";
import { Marker } from "@react-google-maps/api";
import Map from "../Common/Map/index.jsx";

const LocationsMap = (data) => {
  const locations = data.tabs;
  const tab1 = locations[0],
    tab2 = locations[1];

  const [pins, setPins] = useState("usa");
  const center = useMemo(() => {
    if (pins === "usa") {
      return { lat: 39.8283, lng: -98.5795 };
    } else if (pins === "canada") {
      return { lat: 56.1304, lng: -106.3468 };
    }
  }, [pins]);

  const clickLocation = function(url){
    window.location = url;
  }

  const zoom = 4;
  const customClass = "locationsmap__map";
  const icon = "/images/icons/map-pin.png";

  const AllPins = getAllPins([tab1, tab2]);

  return (
    <div className="locationsmap">
      <Map zoom={zoom} center={center} customClass={customClass}>
        {AllPins.map((pin, index) => {
          return (
            <Marker
              key={pin.url}
              position={{ lat: pin.latitude, lng: pin.longitude }}
              icon={icon}
              onClick={() => clickLocation(pin.url)}
              title={pin.name}
            />
          );
        })}
      </Map>

      <div className="locationsmap--list">
        <div className="locationsmap--categories">
          <div
            onClick={() => setPins("usa")}
            className={`locationsmap--categories-item ${
              pins === "usa" ? "active" : ""
            }`}
          >
            <h2 className={`h2-alt ${pins === "usa" ? "active" : ""}`}>
              {tab1.name}
            </h2>
          </div>
          <div
            onClick={() => setPins("canada")}
            className={`locationsmap--categories-item ${
              pins === "canada" ? "active" : ""
            }`}
          >
            <h2 className={`h2-alt ${pins === "canada" ? "active" : ""}`}>
              {tab2.name}
            </h2>
          </div>
          <div className="locationsmap--categories-item">
            <h2 className="h2-alt">
              <a href={data.link.url}>{data.link.name}</a>
            </h2>
          </div>
        </div>

        <section className="locationsmap--lists">
          <div
            onClick={() => setPins("usa")}
            className={`locationsmap--categories-item ${
              pins === "usa" ? "active" : ""
            }`}
          >
            <h2 className={`h2-alt ${pins === "usa" ? "active" : ""}`}>US</h2>
          </div>
          <div className={`list ${pins === "usa" ? "active" : ""}`}>
            <ul>
              {tab1.locations.map((state, index) => {
                return (
                  <li key={index}>
                    <h3 className="h3-alt">{state.state}</h3>
                    <ul>
                      {state.offices.map((office, index) => (
                        <li key={index}>
                          <a href={office.url}>{office.name}</a>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            onClick={() => setPins("canada")}
            className={`locationsmap--categories-item ${
              pins === "canada" ? "active" : ""
            }`}
          >
            <h2 className={`h2-alt ${pins === "canada" ? "active" : ""}`}>
              {tab2.name}
            </h2>
          </div>
          <div className={`list ${pins === "canada" ? "active" : ""}`}>
            <ul>
              {tab2.locations.map((state, index) => {
                return (
                  <li key={index}>
                    <h3 className="h3-alt">{state.state}</h3>
                    <ul>
                      {state.offices.map((office, index) => (
                        <li key={index}>
                          <a href={office.url}>{office.name}</a>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="locationsmap--categories-item">
            <h2 className="h2-alt">
              <a href={data.link.url}>{data.link.name}</a>
            </h2>
          </div>
        </section>
      </div>
    </div>
  );
};

function getAllPins(tabs) {
  let allPins = [];

  for (let i = 0; i < tabs.length; i++) {
    let thisTab = tabs[i];
    if (!thisTab || !thisTab.locations) {
      continue;
    }

    let thisTabPins = thisTab.locations
      .map((state) => {
        return state.offices.map((office) => {
          return office;
        });
      })
      .flat();

    allPins = allPins.concat(thisTabPins);
  }

  return allPins;
}

export default LocationsMap;
